import { render, staticRenderFns } from "./Directory.vue?vue&type=template&id=2ba84781&scoped=true&"
import script from "./Directory.vue?vue&type=script&lang=js&"
export * from "./Directory.vue?vue&type=script&lang=js&"
import style0 from "./Directory.vue?vue&type=style&index=0&id=2ba84781&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ba84781",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VAvatar,VCard,VList,VListItem,VListItemAction,VListItemContent,VListItemSubtitle,VListItemTitle,VVirtualScroll})
