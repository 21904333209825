<template>
  <div>
    <v-card
      flat
      v-if="showFinalData.length > 0"
    >
      <v-list
        class="pt-0 ma-n4" 
      >
        <v-virtual-scroll
          :items="showFinalData"
          :item-height="90"
          style="max-height:1500px"
        >
          <template v-slot="{ item }">
            <v-list-item
              @click="showProfile(item.det_user_id);"
              :key="'profile_'+item.det_user_id"
              tabindex="0"
              style="height:90px;"
            >
              <v-list-item-action class="ml-0 mr-4 pt-2 my-4">
                <v-avatar class="avatar">
                  {{ item.avatar_char }}
                </v-avatar>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.positionTitle ? item.positionTitle : '' }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ item.org_unit ? item.org_unit : '' }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-list>
    </v-card>
    <v-card
      flat
      v-if="showFinalData.length == 0"
      style="text-align:center"
      class="mt-10 flex"
    >
      <div
        role=""
        class="pt-10"
      >
        <span
          class="pt-4
         pb-7 flex-start"
        >
          <img
            alt="search image"
            src="../../public/assets/search.svg"
          >
        </span>
      </div>
      <span
        role="heading"
        class="noItem"
        aria-level="1"
      >No matching result found.</span>
    </v-card>
  </div>
</template>
<script>
import loadash from 'lodash';
  export default {
    name: "Directory",
    props: {
      directoryData: {
        type: Array,
        default: function(){
          return [];
        }
      },
      filter:{
        type: String,
        default: ""
      }
    },
    computed: {      
      mappedData() {
        let directoryData = this.directoryData;
        let showData = this.directoryData.slice(0,1000);
        if(this.filter != ""){
          showData = directoryData.filter( person => person.first_name  && person.first_name.toLowerCase().indexOf(this.filter) > -1 || person.last_name && person.last_name.toLowerCase().indexOf(this.filter) > -1 
             || person.position_title && person.position_title.toLowerCase().indexOf(this.filter) > -1 || person.organizational_unit && person.organizational_unit.toLowerCase().indexOf(this.filter) > -1
          );
        }
        return showData ? loadash.sortBy(showData,'first_name'): [];
      },
      showFinalData(){
        let finalData = this.mappedData && this.mappedData.length ? this.mappedData.map(obj =>{
          return { det_user_id: obj.det_user_id,avatar_char: obj.first_name.charAt(0),fullName : obj.first_name + ' '+ obj.last_name,positionTitle: obj.position_title ? obj.position_title : '',
            org_unit: obj.organizational_unit ? obj.organizational_unit : '' }
        }): [];
        return finalData;
      }
    },
    methods:{
      showProfile(user_id){
        this.$router.push('./profile/'+user_id);
      }
    }
  }
</script>
<style scoped>
.avatar{
  background-color:#C8DCF0;
}
</style>