<template>
  <div>
    <appBarMobile
      color="primary"
      :title="title"
      :extended="extendedRequired"
      :search-dialog="showDialog"
      :search-icon="searchIcon"
      :show-search-filter="showSearchFilter"
      search-filter-type="Directory"
      search-label="Refine by name, title or org unit"
      :show-search-input="showSearchInput"
      :show-back-button="showBackButton"
      :reset-selection="resetSearch"
      @hideSearchField="showSearchInput = false;searchDirectory(true)"
    >
      <template slot="extension">
        <div
          style="width:100%"
          class="pt-0 pa-3"
        >
          <v-text-field
            label="First name"
            outlined
            ref="searchContent"
            clearable
            clear-icon="mdi-close-circle"
            single-line
            filled
            background-color="#fff"
            light
            color="gray"
            hide-details
            height="56px"
            full-width
            tabindex="0"
            v-model="firstName"
          />
          <v-text-field
            label="Last name"
            outlined
            ref="searchContent"
            clearable
            clear-icon="mdi-close-circle"
            single-line     
            height="56px"
            filled
            background-color="#fff"
            light
            color="gray"
            hide-details
            full-width
            v-model="lastName"
            class="pt-2 pb-3"
          />
          <v-btn
            class="info"
            @click="searchDirectory()"
            tile
          >
            Search
          </v-btn>
        </div>
      </template>
    </appBarMobile>
    <div
      role="list"
      v-if="recentSearch.length > 0 && this.searchText == ''"
    >
      <div
        class="d-flex justify-space-between recentSearch directory"
      >
        <span
          role="heading"
          aria-level="1"
          class="recent--search"
          tabindex="0"
        >Recent Searches</span>
        <span
          role="button"
          tabindex="0"
          class="align-end"
          @keyup.enter="resetRecent()"
          @click="resetRecent()"
        >
          <v-btn
            tabindex="-1"
            class="recent--btn pr-0"
            height="33px"
            text
          >CLEAR</v-btn>
        </span>
      </div>
      <div
        class="pt-1 d-flex flex-grow justify-space-between directory"
        style="line-height:49px;"
        v-for="(contact,index) in recentSearch"
        role="listitem"
        :key="'recent_'+index"
      >
        <span
          @click="searchRecent(contact.name)"
          @keyup.enter="searchRecent(contact.name)"
          tabindex="0"
          class="align-self-start grow recentSearch"
        >{{ contact.name }}</span>
      </div>
    </div>
    <div
      class="pa-10 directory"
      v-if="recentSearch.length == 0"
    >
      <div
        class="d-flex pa-7 justify-center"
      >
        <img
          @click="searchDialog = true"
          @keyup.enter="searchDialog = true"
          aria-label="double tap to search"
          alt="search image"
          tabindex="0"
          src="../../public/assets/search.svg"
        >
      </div>
      <div
        class="d-flex pt-0 pa-0 justify-center"
      >
        <span
          aria-level="1"
          role="heading"
          class="no--recent"
        >Click magnifying glass above to search for staff.</span>
      </div>
    </div>
    <Directory
      v-if="searchText != '' && directoryData.length > 0"
      :filter="showSearchInput ? filters.Directory : ''"
      :directory-data="directoryData"
      class="directory"
    />
    <div
      class="pa-10 directory"
      v-if="searchText != '' && directoryData.length == 0"
    >
      <div
        class="d-flex pa-7 justify-center"
      >
        <img
          alt="Search Image"
          src="../../public/assets/search.svg"
        >
      </div>
      <div
        class="d-flex pt-0 pa-2 justify-center"
      >
        <span
          role="heading"
          class="no--recent"
          aria-level="1"
        >Your search did not find any matching staff.
          <div
            class="pt-5 pl-5"
            style="text-align:left"
          > Suggestions:
            <ul
              class="pt-2"
              style="text-align:left"
            >
              <li>Make sure that all words are spelled correctly.</li>
              <li>Try different keywords.</li>
              <li>Try more general keywords.</li>
            </ul>
          </div>
        </span>
      </div>
    </div>
    <div
      role="dialog"
      v-if="searchDialog"
    >
      <template>
        <v-row justify="center">
          <v-dialog 
            v-model="searchDialog"
            width="280"
            content-class="directory"
            retain-focus
          >
            <v-card role="contentinfo">
              <v-card-title class="headline" />
              <v-card-text class="pb-0">
                <div
                  style="width:100%"
                  class="pt-0"
                >
                  <v-text-field
                    label="First name"
                    v-model="firstName"
                    autofocus
                    hide-details
                    tabindex="0"
                    clearable
                    clear-icon="mdi-close-circle"
                  />
                  <v-text-field
                    label="Last name"
                    v-model="lastName"
                    class="pt-5 pb-0"
                    hide-details
                    clearable
                    clear-icon="mdi-close-circle"
                  />
                </div>
              </v-card-text>
              <v-card-actions class="d-flex">
                <v-spacer />
                <v-btn
                  color="navy"
                  class="font-weight-medium align-self-end"
                  text
                  role="button"
                  tabindex="0"
                  @click="searchDialog = false"
                >
                  CANCEL
                </v-btn>
                <v-btn
                  color="navy"
                  tabindex="0"
                  class="font-weight-medium align-self-end"
                  text
                  role="button"
                  @click="searchDirectory()"
                >
                  SEARCH
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Directory from "@/components/Directory";
import appBarMobile from "@/components/appBarMobile";
import lodash from 'lodash';
import utils from '@/settings/utils';
export default {
  components: {
    appBarMobile,
    Directory
  },
  computed: {
    ...mapGetters({
      isLoading: "isLoading",
      directoryData: "directory",
      filters: "filters",
      tokens: "tokens"
    }),
    recentSearch() {
      let recentContacts = this.recentContacts;
        if(localStorage && utils.getLocalStorage(this.tokens.user_name,"recentSearch") != null){
          recentContacts = utils.getLocalStorage(this.tokens.user_name,"recentSearch");
        }
      recentContacts = recentContacts.reverse();
      recentContacts = lodash.uniqBy(recentContacts,"name");
      return recentContacts;
    }
  },
  data: function() {
    return {
      recentContacts: Array(),
      showSearchFilter: true,
      title: "Staff Directory",
      showSearchInput: false,
      searchIcon: 'search',
      showBackButton: false,
      searchText: "",
      extendedRequired: false,
      searchDialog: false,
      firstName: "",
      lastName: ""
    };
  },
  created(){
    this.$store.dispatch('ShowLoading',true);
      setTimeout(()=>{
        this.$store.dispatch('ShowLoading',false);
      },2000);
    this.$store.dispatch("setFilterValue",{Directory:""});
    if(this.$route.path == '/directory/search'){
      this.searchDirectory(true);
    }
    window.scrollTo(0,0);
  },
  methods: {
    searchDirectory(showRecentSearch) {
      this.firstName = this.firstName ? this.firstName.trim():"";
      this.lastName = this.lastName ? this.lastName.trim():"";
      if ((this.firstName != "" || this.lastName != "") && (this.firstName.length >=2 || this.lastName.length >=2) && !showRecentSearch) {
        let that = this;
        this.$store.dispatch("fetchDirectory",{first_name: this.firstName , last_name: this.lastName}).then(function(){
        that.title=that.directoryData ? that.directoryData.length+' Results':'0 Results';
        that.searchText = that.firstName + ' '+ that.lastName;       
        let recentContacts = [];
        if(localStorage && utils.getLocalStorage(that.tokens.user_name,"recentSearch") != null){
          recentContacts = utils.getLocalStorage(that.tokens.user_name,"recentSearch");
        }
        let contactLength = recentContacts.length;
        if(contactLength > 0 && recentContacts[contactLength - 1].name && (recentContacts[contactLength - 1].name).trim().toLowerCase() != that.searchText.trim().toLowerCase() || contactLength == 0){
        recentContacts.push({'name':that.searchText});
        if(localStorage){
          let recentString = JSON.stringify(recentContacts);
          utils.setLocalStorage(that.tokens.user_name,"recentSearch",recentString);
        }
        }
        that.showSearchFilter = true;
        that.searchDialog = false;
        that.recentContacts = recentContacts;
        that.showBackButton = true;
        that.searchIcon="filter_list";
        if(that.$route.path.indexOf('/directory') != -1){
        that.$router.push('/search');
        }else{
          that.$router.push('directory/search');
        }
        });
      }else if(showRecentSearch){
        this.title=this.directoryData ? this.directoryData.length+' Results':'0 Results';
        this.searchText = "research";
        this.showSearchFilter = true;
        this.searchDialog = false;
        this.searchIcon="filter_list";
        let filters = this.filters.Directory;
        if(filters == ""){
          this.showBackButton = true;
        }else{
          this.showSearchInput = true;
        }
      }
    //eslint-disable-next-line 
    NativeJS.directorySearched(this.firstName, this.lastName);
    },
    showDialog() {
      if(this.searchText == ''){
      this.searchDialog = true;
      }else{
        this.showBackButton = false;
        this.title = '';
        this.showSearchInput = true;
      }
    },
    resetRecent() {
      let recentContacts = [];
        if(localStorage){
          let recentString = JSON.stringify(recentContacts);
          utils.setLocalStorage(this.tokens.user_name,"recentSearch",recentString);
        }
        this.recentContacts = [];
    },
    resetSearch(){
        this.title="Staff Directory";
        this.searchText = '';
        this.firstName = '';
        this.lastName = '';
        this.showBackButton = false;
        this.showSearchInput = false;
        this.searchIcon = "search";
        this.$router.push('/people');
    },
    searchRecent(searchText){
      let searchName = searchText.split(" ");
      this.firstName = searchName[0] != null ? searchName[0]:'';
      this.lastName = searchName[1] != null ? searchName[1]:'';
      this.searchDirectory();
    }
  },
  watch:{
    $route(to){
      if(to.name=="People"){
        this.title="Staff Directory";
        this.searchText = '';
        this.firstName = '';
        this.lastName = '';
        this.showBackButton = false;
        this.showSearchInput = false;
        this.searchIcon = "search";
      }
    }
  }
};
</script>

<style scoped>
.recent--search {
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #121212;
}
.theme--dark ::v-deep .v-input input{
    caret-color: #f4f4f7 !important;
}
::v-deep .v-dialog--active .v-text-field__slot{
  padding-top:15px;
}
::v-deep .v-dialog--active .v-input__append-inner{
  padding-top:15px;
}
::v-deep .v-input__append-inner{
  padding-top:12px;
  width: 48px;
  height: 48px;
}
.recent--btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #041e42;
}
.no--recent {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #121212;
}
</style>